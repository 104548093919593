function pagePc() {
    let rootValue = 92;
    // let rootValue = 32;
    // fontSize / width = rootValue / 1920
    let width = document.documentElement.clientWidth || document.body.clientWidth;
    if( width <= 1280 ) width = 1280;
    let rem = "";
    rem = width * rootValue / 1920;
    document.documentElement.style.fontSize = rem + "px";
}



pagePc();
window.onresize = function () {
    pagePc();
};