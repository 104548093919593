  <template>
  <div id="app">
    <div class="topbar">
      <div class="topbar-center">
        <div class="left iconfont icon-logo-heygears"></div>
        <div class="right">
          <span class="contact" @click="goContactMsg">联系我们</span>
          <!-- <span>中文 | EN</span> -->
          <!-- <span class="btn">注册</span> -->
          <span class="btn login" @click="goLogin">登录</span>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="slide-box" ref="slide-box">
        <div class="slide-item tab-1">
          <div class="float-box">
            <h3 class="tab-1-title">HeyGears Cloud·椅旁解决方案</h3>
            <div class="tab-1-subtitle">以患者诊疗为核心，为椅旁即刻诊疗而生的一站式3D打印服务平台。</div>
            <div class="tab-1-use-btn" @click="goLogin">立即使用</div>
          </div>
        </div>
        <div class="slide-item tab-2">
          <div class="tab-2-title">
            <span>稳定、高效、专业的设计服务。</span>
            <br />
            <span>助力齿科数字化转型，进入椅旁即刻诊疗新时代。</span>
          </div>
          <div class="float-box">
            <div class="content-container">
              <div class="left">
                <el-carousel
                  ref="carousel"
                  @change="currentSwiperTabIndex = $event"
                  indicator-position="outside"
                  :interval="5000"
                >
                  <el-carousel-item v-for="item in tabData" :key="item">
                    <!-- <h3>{{ item }}</h3> -->
                    <img :src="item" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="right">
                <div
                  :class="['swiper-item-tab', { active: currentSwiperTabIndex === index }]"
                  v-for="(item, index) of swiper_item_options"
                  :key="item"
                  @click="$refs.carousel.setActiveItem(index)"
                >
                  {{ item }}
                  <span :class="['triangle', {active: currentSwiperTabIndex == index}]"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item tab-3">
          <!-- <div class="tab-3-container center"> -->
          <div class="left">
            <div class="tab-3-title">数字化模型一键前处理，轻松易上手</div>
            <div class="tab-3-sub-title">
              <ul>
                <li>面向齿科的专业数据处理，高效自动化的AI智能识别、镂空、排版、切片</li>
                <li>以应用为导向的定制自动化流程，种植、正畸、修复应用均有相应流程</li>
              </ul>
            </div>
          </div>
          <div class="right"></div>
          <!-- </div> -->
        </div>
        <div class="slide-item tab-4">
          <!-- <div class="center"> -->
          <div class="left"></div>
          <div class="right">
            <div class="tab-4-title">齿科应用生产集群化管理， 智能高效</div>
            <div class="tab-4-sub-title">
              <ul>
                <li>设备状态、打印材料等信息实时掌控，在线下发打印任务至3D打印机</li>
                <li>实时监控生产状态，异常状况预警功能，让生产管理更智能、更高效</li>
              </ul>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="slide-item tab-5">
          <div class="tab-5-title center">联系我们</div>
          <!-- <div class="tab-5-container center"> -->
          <div class="tab-5-content clearfix">
            <div class="left center-vertical">
              <div class="contact-box">
                <div class="contact-box-label">中国广州（总部）</div>
                <div class="contact-address">
                  <i class="iconfont icon-icon_address"></i>广东省广州市黄埔区开发区科技企业加速器B2栋5-6层
                </div>
                <div class="contact-phone">
                  <i class="iconfont icon-icon_telephone"></i>+86 020-32202686
                </div>
              </div>
              <div class="contact-box">
                <div class="contact-box-label">美国</div>
                <div class="contact-address">
                  <i class="iconfont icon-icon_address"></i>18001 Sky Park Circle, Suit G, Irvine, CA 92614
                </div>
                <div class="contact-phone">
                  <i class="iconfont icon-icon_telephone"></i>+1 949-418-9418
                </div>
              </div>
              <div class="contact-box">
                <div class="contact-box-label">中国深圳</div>
                <div class="contact-address">
                  <i class="iconfont icon-icon_address"></i>广东省深圳市南山区大冲商务中心B座1702单元
                </div>
                <div class="contact-phone">
                  <i class="iconfont icon-icon_telephone"></i>+86 0755-86545786
                </div>
              </div>
            </div>
            <div class="right"></div>
          </div>
          
          <!-- </div> -->
          <div class="copyright">
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009187"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="police" class="police-img" />
              <span>粤公网安备 44010602009187号</span>
            </a>
            <a href="https://beian.miit.gov.cn/">粤ICP备16021396号-6</a>
            <span>COPYRIGHT © 2020 HEYGEARS 广州黑格智能科技有限公司 版权所有</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import police from "./assets/police.png";
import pic_page2_01 from "./assets/pic_page2_01.png";
import pic_page2_02 from "./assets/pic_page2_02.png";
import pic_page2_03 from "./assets/pic_page2_03.png";
import pic_page2_04 from "./assets/pic_page2_04.png";
export default {
  name: "App",
  title:'数字化运营平台',
  data() {
    return {
      swiper_item_options: [
        "覆盖齿科四大领域：种植、正畸、固定修复、活动修复",
        "引导式的订单填写，轻松快速下单",
        "订单状态可视化，随时随地了解设计进展",
        "在线的医技沟通与方案预览，使设计更高效、更科学"
      ],
      police: police,
      currentSwiperTabIndex: 0,
      tabData: [
        pic_page2_01,
        pic_page2_02,
        pic_page2_03,
        pic_page2_04
      ]
    };
  },
  methods: {
    goLogin() {
      window.location.href = "https://user-clinic.heygears.cloud/";
    },
    goContactMsg() {
      // this.currentSwiperTabIndex = 4;
      this.silde(4);
    },
    //上下滚动时的具体处理函数
    handle(delta) {
      if (!this.able) return;
      this.able = false;
      if (delta < 0) {
        //向下滚动
        if (this.currentIndex < this.length - 1) {
          // 非最后一个
          // this.$refs["slide-box"].style.transform = `translateY(-${++this
          //   .currentIndex * 100}%)`;
          this.silde(++this.currentIndex);
        }
      } else {
        //向上滚动
        if (this.currentIndex > 0) {
          // 非第一个
          this.silde(--this.currentIndex);
          // this.$refs["slide-box"].style.transform = `translateY(-${--this
          //   .currentIndex * 100}%)`;
        }
      }
      setTimeout(() => {
        this.able = true;
      }, 1400);
      console.log(delta, this.currentIndex);
    },
    silde(index) {
      this.$refs["slide-box"].style.transform = `translateY(-${index * 100}%)`;
    }
  },
  mounted() {
    document.title = '数字化运营平台'
    this.length = this.$refs["slide-box"].children.length;
    this.currentIndex = 0;
    this.able = true;
    let that = this;
    if (window.addEventListener)
      //FF,火狐浏览器会识别该方法
      window.addEventListener("DOMMouseScroll", wheel, false);
    window.onmousewheel = document.onmousewheel = wheel; //W3C
    //统一处理滚轮滚动事件
    function wheel(event) {
      var delta = 0;
      if (!event) event = window.event;
      if (event.wheelDelta) {
        //IE、chrome浏览器使用的是wheelDelta，并且值为“正负120”
        delta = event.wheelDelta / 120;
        if (window.opera) delta = -delta; //因为IE、chrome等向下滚动是负值，FF是正值，为了处理一致性，在此取反处理
      } else if (event.detail) {
        //FF浏览器使用的是detail,其值为“正负3”
        delta = -event.detail / 3;
      }
      if (delta) that.handle(delta);
    }
  }
};
</script>

<style lang="scss">
$mainColorBlue: #3054cc;
$fontColorFirst: #e4e8f7;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  min-width: 1280px !important;
  height: 100vh;
  .center {
    // position: absolute;
    // left: 50%;
    // // margin-left: 50%;
    // transform: translateX(-50%);
  }
  // .center-vertical {
  //  vertical-align: middle;
  // }
}
html,
body {
  min-width: 1280px !important;
  overflow-y: auto;
}
body {
  font-size: 16px;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0 !important;
}
.view {
  height: calc(100vh - 80px);
  overflow: hidden;
  padding-top: 80px;
  box-sizing: content-box;
}
.slide-box {
  height: 100%;
  transform: translateY(-0%);
  transition: 1.2s;
  .slide-item {
    width: 100%;
    height: 100%;
    background: url("./assets/bg_homepage_dot.png");
    background-size: 100% 100%;
    // background-size: cover;
    position: relative;
  }

  .tab-1 {
    background: url("./assets/pic_homepage_background.png") no-repeat;
    // background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    .float-box {
      width: 1200px;
      margin-left: 50%;
      transform: translate(-50%, -50%);
      // padding-top: 282px;
      position: absolute;
      top: 48%;

      box-sizing: border-box;
    }
    .tab-1-title {
      font-weight: bold;
      font-size: 40px;
      color: #e4e8f7;
      margin-bottom: 32px;
    }
    .tab-1-subtitle {
      font-size: 16px;
      color: #e4e8f7;
      margin-bottom: 48px;
    }
    .tab-1-use-btn {
      cursor: pointer;
      width: 112px;
      height: 40px;
      line-height: 40px;
      background: #3760ea;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
    }
  }
  .tab-2 {
    background-image: url("./assets/icon_number_01.png"),
      url("./assets/bg_homepage_dot.png");
    background-position: 50% 12%, 0 0;
    background-size: 228px 156px, 100% 100%;
    background-repeat: no-repeat, no-repeat;
    .tab-2-title {
      // background: url("./assets/icon_number_01.png") no-repeat;
      // background-position: 50% 50px;
      color: #e4e8f7;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      // padding-top: 200px;
      // margin-bottom: 100px;
      position: absolute;
      top: 22.44%;
      left: 50%;
      transform: translateX(-50%);
    }
    .content-container {
      position: absolute;
      left: 50%;
      top: 44.88%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      width: 1200px;
      .left {
        width: 592px;
        height: 370px;
      }
      .right {
        .swiper-item-tab {
          box-sizing: border-box;
          height: 56px;
          padding: 0 20px 0 42px;
          background: rgba(228, 232, 247, 0.1);
          color: $fontColorFirst;
          font-size: 16px;
          line-height: 56px;
          margin-bottom: 32px;
          position: relative;
          &.active {
            background: transparent;
            border: 1px solid $mainColorBlue;
          }
          .triangle {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            &.active {
              &::before {
                display: inline-block;
                content: "";
                width: 0;
                height: 0;
                border-top: 5px solid $mainColorBlue;
                border-right: 5px solid $mainColorBlue;
                border-bottom: 5px solid transparent;
                border-left: 5px solid transparent;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
  .tab-3 {
    // padding-top: 200px;
    background: url("./assets/icon_number_02.png") no-repeat,
      url("./assets/bg_homepage_dot.png") no-repeat;
    background-size: 226px 156px, 100% 100%;
    background-position: 360px 30%, 0 0;
    // .tab-3-container {
    //   width: 1200px;
    //   display: flex;
    //   justify-content: space-between;
    // }

    .left {
      position: absolute;
      top: 38.88%;
      left: 360px;
      // padding-top: 200px;
      // background: url("./assets/icon_number_02.png") no-repeat;
      // background-position: 0 60px;
      .tab-3-title {
        margin-bottom: 48px;
        color: $fontColorFirst;
        font-size: 24px;
        font-weight: 600;
      }
      .tab-3-sub-title {
        ul {
          li {
            margin-bottom: 24px;
            color: $fontColorFirst;
            font-size: 14px;
            list-style: none;
            &::before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: $mainColorBlue;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .right {
      position: absolute;
      top: 28.22%;
      left: 1122px;
      width: 426px;
      height: 426px;
      background: url("./assets/gif_page3.gif");
      background-size: 100% 100%;
    }
  }
  .tab-4 {
    background: url("./assets/icon_number_03.png") no-repeat,
      url("./assets/bg_homepage_dot.png") no-repeat;
    background-size: 228px 156px, 100% 100%;
    background-position: 1102px 36%, 0 0;
    // padding-top: 200px;
    // .center {
    // display: flex;
    // justify-content: space-between;
    // width: 1200px;
    .left {
      position: absolute;
      top: 30.78%;
      left: 360px;
      width: 639px;
      height: 400px;
      // margin-right: 102px;
      background: url("./assets/gif_page4.gif");
      background-size: 100% 100%;
    }
    .right {
      position: absolute;
      top: 43.11%;
      left: 1104px;
      // background: url("./assets/icon_number_03.png") no-repeat;
      // background-position: 0 60px;
      // padding-top: 200px;
      .tab-4-title {
        margin-bottom: 48px;
        color: $fontColorFirst;
        font-size: 24px;
        font-weight: 600;
      }
      ul {
        li {
          margin-bottom: 24px;
          color: $fontColorFirst;
          font-size: 14px;
          list-style: none;
          &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $mainColorBlue;
            margin-right: 8px;
          }
        }
      }
    }
    // }
  }
  .tab-5 {
    min-height: 800px;
    .center-vertical{
      float: left;
    }
    .right{
      float: right;
    }
    .tab-5-content{
      margin: 0 auto;
      padding-top: 80px;
      overflow: hidden;
      width: 1200px;
    }
    .tab-5-title {
      width: 100%;
      text-align: center;
      padding-top: 80px;
      // padding-top: 60px;
      // display: inline-block;
      // margin-bottom: 80px;
      color: $fontColorFirst;
      font-size: 24px;
      font-weight: 600;
    }
    // .tab-5-container {
    //   display: flex;
    //   justify-content: space-between;
    //   width: 1200px;
    //   position: relative;
    .left {
      // padding-top: 60px;
      .contact-box {
        margin-bottom: 60px;
        text-align: left;
        .contact-box-label {
          margin-bottom: 32px;
          color: $fontColorFirst;
          font-size: 16px;
          font-weight: 600;
        }
        .contact-address,
        .contact-phone {
          margin-bottom: 20px;
          color: $fontColorFirst;
          font-size: 16px;
          i {
            font-size: 24px;
            margin-right: 18px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .right {
      position: absolute;
      top: 26.11%;
      left: 960px;
      // margin-left: 178px;
      width: 600px;
      height: 520px;
      background: url("./assets/pic_heygears.png");
      background-size: 100% 100%;
    }
    // }
    .copyright {
      position: absolute;
      left: 0;
      bottom: 20px;
      width: 100%;
      color: #939393;
      text-align: center;
      .police-img {
        vertical-align: middle;
        margin-right: 8px;
      }
      a {
        text-decoration: none;
        color: #939393;
        margin-right: 16px;
      }
    }
  }
}

.el-carousel__item img {
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  width: 100%;
}

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n + 1) {
//   background-color: #d3dce6;
// }

#app {
  width: 100%;
  height: 100vh;
  .topbar {
    width: 100%;
    background: #1d1d1f;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .topbar-center {
      width: 1200px;
      box-sizing: border-box;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      
      margin-left: 360px;
    }
    .left {
      
      flex-shrink: 0;
      color: #fff;
      font-size: 38px;
      // margin-right: 750px;
    }
    .right {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        color: #fff;
        margin-left: 48px;
        font-size: 14px;
        color: #83868f;
      }
      .contact {
        cursor: pointer;
      }
      .btn {
        cursor: pointer;
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #3054cc;
        color: #3054cc;
        font-size: 16px;
        &.login {
          background: #3054cc;
          color: #e4e8f7;
          margin-left: 24px;
        }
      }
    }
  }
  .main-container {
    .swiper-1 {
      height: 100%;
      border: 1px solid #000;
      .title-box {
        text-align: center;
      }
    }
  }
}
</style>

<style lang="scss">
.el-carousel {
  .el-carousel__indicators {
    .el-carousel__indicator {
      .el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
}
</style>