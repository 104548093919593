import Vue from 'vue'
import App from './App.vue'
import {
  Carousel,
  CarouselItem
} from 'element-ui';
import './utils/pc.js';
import 'element-ui/lib/theme-chalk/index.css';
import './iconfont/iconfont.css';

Vue.use(Carousel);
Vue.use(CarouselItem);


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')




